import React, { useMemo, useContext } from 'react';
import classNames from 'classnames';
import icon from '@devsisters/ui-common/lib/icon';

import { mediaTypeContext } from './Layout';
import * as styles from './Pagination.module.scss';

export function usePagination<T>(everyPageItems: T[], itemPerPage: number, currentPage: number) {
  if (itemPerPage < 1) throw new Error();
  const start = itemPerPage * currentPage;
  return {
    currentPageItems: everyPageItems.slice(start, start + itemPerPage),
    pageCount: Math.max(1, Math.ceil(everyPageItems.length / itemPerPage)),
  };
}

interface PaginationProps {
  className?: string;
  fov: number; // 한 번에 표시할 수 있는 페이지 갯수
  total: number; // 전체 페이지 갯수
  current: number; // 0 based index
  change: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = props => {
  const { fov, total, current, change } = props;
  const handlers = useMemo(() => {
    if (fov < 1) throw new Error();
    if (total < 1) throw new Error();
    if (current < 0) throw new Error();
    const half = fov >> 1;
    const plus = fov % 2;
    const start = Math.max(0, Math.min(total - fov, current - half));
    const end = Math.max(Math.min(fov, total), Math.min(total, current + half + plus));
    const changePageWithScroll = (page: number) => {
      change(page);
      window.scrollTo({ top: 0 });
    };
    return {
      goToStart: () => changePageWithScroll(0),
      goToEnd: () => changePageWithScroll(total - 1),
      goToPrev: () => changePageWithScroll(Math.max(0, current - 1)),
      goToNext: () => changePageWithScroll(Math.min(total - 1, current + 1)),
      pages: Array(end - start)
        .fill(0)
        .map((_, i) => {
          const curr = start + i;
          return [curr, () => changePageWithScroll(curr)] as [number, () => void];
        }),
    };
  }, [fov, total, current, change]);
  const mediaType = useContext(mediaTypeContext);
  const isPc = mediaType === 'pc';
  return isPc ? (
    <PcPagination {...props} {...handlers} />
  ) : (
    <MobilePagination {...props} {...handlers} />
  );
};

export default Pagination;

interface PaginationComponentProps extends PaginationProps {
  goToStart: () => void;
  goToEnd: () => void;
  goToPrev: () => void;
  goToNext: () => void;
  pages: [number, () => void][];
}

const PcPagination: React.FC<PaginationComponentProps> = ({
  className,
  current,
  goToStart,
  goToEnd,
  goToPrev,
  goToNext,
  pages,
}) => {
  return (
    <div className={classNames(styles.pcPagination, className)}>
      <span onClick={goToStart}>{'<<'}</span>
      <span className={styles.prevButton} onClick={goToPrev}>
        {'<'}
      </span>
      <div className={styles.list}>
        {pages.map(([i, onClick]) => (
          <span
            key={i}
            onClick={onClick}
            className={classNames({ [styles.current]: current === i })}>
            {i + 1}
          </span>
        ))}
      </div>
      <span className={styles.nextButton} onClick={goToNext}>
        {'>'}
      </span>
      <span onClick={goToEnd}>{'>>'}</span>
    </div>
  );
};

const MobilePagination: React.FC<PaginationComponentProps> = ({
  className,
  current,
  goToPrev,
  goToNext,
  pages,
}) => {
  return (
    <div className={classNames(styles.mobilePagination, className)}>
      <div onClick={goToPrev}>
        <PrevIcon />
      </div>
      {pages.map(([i, onClick]) => (
        <span key={i} onClick={onClick} className={classNames({ [styles.current]: current === i })}>
          {i + 1}
        </span>
      ))}
      <div onClick={goToNext}>
        <NextIcon />
      </div>
    </div>
  );
};

const PrevIcon = icon(
  '0 0 62 62',
  <g className={styles.lineIcon}>
    <polyline points="35.59,19.35 23.93,31 35.59,42.65" />
    <rect x="0" y="0" width="62" height="62" />
  </g>
);

const NextIcon = icon(
  '0 0 62 62',
  <g className={styles.lineIcon}>
    <polyline points="26.41,19.35 38.07,31 26.41,42.65" />
    <rect x="0" y="0" width="62" height="62" />
  </g>
);
