import React, { useContext, useCallback } from 'react';
import classNames from 'classnames';
import * as qs from 'qs';
import { Link, graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { getAvatarImage, addTrailingSlash } from '../util';
import { mediaTypeContext } from '../components/Layout';
import Frame from '../components/Frame';
import Pagination, { usePagination } from '../components/Pagination';
import * as styles from './index.module.scss';

type IndexPageProps = {
  location: Location;
  data: {
    allMarkdownRemark: {
      edges: { node: Gatsby.Node }[];
    };
    avatars: {
      edges: { node: { id: string; filename: string } }[];
    };
  };
};

interface Author {
  displayName: string;
  email: string;
}

const IndexPage: React.FunctionComponent<IndexPageProps> = ({ data }) => {
  const location = useLocation();
  const posts = data.allMarkdownRemark.edges;
  const avatars = data.avatars.edges.map(edge => edge.node);
  const query = qs.parse(location.search.substr(1));
  const page = Math.max(1, query.page | 0) - 1;
  const changePage = useCallback(
    (page: number) => {
      navigate(`${location.pathname}?${qs.stringify({ page: page + 1 })}`);
    },
    [location.pathname]
  );
  const { currentPageItems, pageCount } = usePagination(posts, 5, page);
  const mediaType = useContext(mediaTypeContext);
  const isPc = mediaType === 'pc';
  return (
    <Frame
      key={page}
      className={classNames(styles.indexPage, styles[mediaType])}
      footer={<Pagination fov={5} total={pageCount} current={page} change={changePage} />}>
      {currentPageItems.map(({ node }) => {
        const authors = node.fields.authors as Author[];
        return (
          <article key={node.fields.path}>
            <Link to={addTrailingSlash(node.fields.path)} className={styles.link}>
              <h1>{node.frontmatter.title}</h1>
              <p className={styles.summary}>{node.frontmatter.summary}</p>
              <div className={styles.meta}>
                <time>{node.fields.date}</time>
                <span className={styles.author}>
                  {authors.map(author => author.displayName).join(', ')}
                </span>
              </div>
            </Link>
            {isPc && (
              <div>
                <img
                  src={getAvatarImage(
                    avatars,
                    authors.map(author => author.email)
                  )}
                />
              </div>
            )}
          </article>
        );
      })}
    </Frame>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { draft: { ne: true } } }
      sort: { order: DESC, fields: [fields___date] }
    ) {
      edges {
        node {
          frontmatter {
            title
            summary
          }
          fields {
            date
            path
            authors {
              author
              displayName
              email
            }
          }
        }
      }
    }
    avatars: allFile(filter: { sourceInstanceName: { eq: "author-avatars" } }) {
      edges {
        node {
          id: name
          filename: base
        }
      }
    }
  }
`;
